<template>
    <div class="google-recaptcha" v-on="$listeners">
        <script type="application/javascript" src="https://www.google.com/recaptcha/api.js?onload=renderRecaptcha&render=explicit" async defer />

        <loader v-if="loading" />

        <div ref="recaptcha" :class="{ 'google-recaptcha__container': true, 'google-recaptcha__container_loading': loading }" />
    </div>
</template>

<script>
    import Loader from '@/components/Loader';

    export default {
        name: 'GoogleRecaptcha',
        components: {
            Loader,
        },
        props: {
            value: String,
        },
        computed: {
            locale() {
                return this.$i18n.locale;
            },
        },
        methods: {
            resize() {
                this.$refs.recaptcha.style.transform = 'unset';
                this.$refs.recaptcha.parentElement.style.height = 'unset';

                const containerWidth = this.$refs.recaptcha.getBoundingClientRect().width;
                const recaptchaWidth = this.$refs.recaptcha.children[0].getBoundingClientRect().width;

                if (containerWidth < recaptchaWidth) {
                    this.$refs.recaptcha.style.transform = `scale(${containerWidth / recaptchaWidth})`;
                    this.$refs.recaptcha.parentElement.style.height = `${this.$refs.recaptcha.getBoundingClientRect().height}px`;
                }
            },
            changeLocale() {
                const recaptcha = this.$refs.recaptcha;
                const iframe = recaptcha.querySelector('iframe');
                const src = iframe.getAttribute('src');

                this.loading = true;

                recaptcha.parentElement.style.height = 'unset';

                if (src.match(/hl=(.*?)&/).pop() !== this.locale) {
                    iframe.setAttribute('src', src.replace(/hl=(.*?)&/, `hl=${this.locale}&`));
                }
            },
        },
        watch: {
            locale() {
                this.changeLocale();
            },
            value: token => (null === token ? grecaptcha.reset() : null),
        },
        data() {
            return {
                loading: true,
            };
        },
        mounted() {
            window.renderRecaptcha = () => {
                const recaptcha = grecaptcha.render(this.$refs.recaptcha, {
                    theme: 'dark',
                    hl: this.locale,
                    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
                    callback: token => this.$emit('input', token),
                    'expired-callback': () => this.$emit('input', null),
                });

                this.$refs.recaptcha.querySelector('iframe').addEventListener('load', () => {
                    this.loading = false;
                    setTimeout(() => this.resize());
                });

                window.addEventListener('resize', () => this.resize());

                return recaptcha;
            };
        },
    };
</script>

<style lang="scss" scoped>
    .google-recaptcha {
        display: flex;
        justify-content: center;

        ::v-deep .loader {
            width: 4.8rem;
            height: 4.8rem;
        }
    }

    .google-recaptcha__container {
        max-width: 100%;
        transform-origin: 0 0;

        &_loading {
            display: none;
        }
    }
</style>
