<template>
    <div class="auth-layout">
        <logo-outlined class="auth-layout__bg-logo" />

        <scroll-container>
            <validation-observer ref="observer" v-slot="{ handleSubmit, reset }" slim>
                <div class="auth-layout__outer">
                    <div class="auth-layout__inner">
                        <div class="auth-layout__header">
                            <logo />

                            <h1 class="auth-layout__title">{{ title }}</h1>
                        </div>

                        <form class="auth-layout__form" @submit.prevent="handleSubmit(submit)">
                            <slot />

                            <p v-if="error" class="auth-layout__error">{{ error }}</p>

                            <google-recaptcha v-if="needsRecaptcha" v-model="recaptchaToken" @input="error = null" ref="recaptcha" />

                            <text-button :loading="loading" full>{{ buttonText }}</text-button>
                        </form>

                        <div class="auth-layout__links">
                            <slot name="links" />
                        </div>
                    </div>

                    <div class="auth-layout__locale-wrapper">
                        <p>{{ $t('auth.choose_language') }}:</p>

                        <locale-select class="auth-layout__locale-select" outlined @input="reset" />
                    </div>
                </div>
            </validation-observer>
        </scroll-container>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import responseProcessingMixin from '@/mixins/responseProcessingMixin';
    import GoogleRecaptcha from '@/components/GoogleRecaptcha';
    import LocaleSelect from '@/components/inputs/LocaleSelect';
    import Logo from '@/components/Logo';
    import LogoOutlined from '@/components/icons/LogoOutlined';
    import ScrollContainer from '@/components/ScrollContainer';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'AuthLayout',
        components: {
            GoogleRecaptcha,
            LocaleSelect,
            Logo,
            LogoOutlined,
            ScrollContainer,
            TextButton,
            ValidationObserver,
        },
        props: {
            action: {
                type: Function,
                required: true,
            },
            buttonText: {
                type: String,
                default: 'Отправить',
            },
            title: {
                type: String,
                required: true,
            },
            needsRecaptcha: {
                type: Boolean,
                default: true,
            },
        },
        mixins: [responseProcessingMixin],
        methods: {
            submit() {
                if (this.needsRecaptcha && !this.recaptchaToken) {
                    this.error = this.$t('auth.recaptcha_verification');
                    return;
                }

                this.loading = true;

                this.action(this.recaptchaToken)
                    .then(() => {
                        if (this.$refs.observer) {
                            this.$refs.observer.reset();
                        }
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.observer))
                    .finally(() => {
                        this.loading = false;
                        this.recaptchaToken = null;
                    });
            },
        },
        data() {
            return {
                error: null,
                loading: false,
                recaptchaToken: null,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .auth-layout,
    .auth-layout__header {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .auth-layout {
        position: relative;
        justify-content: center;
        height: 100%;
        background: radial-gradient(41.41% 73.61% at 52.45% -4.26%, rgba(59, 72, 111, 0.2) 0%, rgba(14, 17, 26, 0.2) 100%);
        overflow: hidden;
    }

    .auth-layout__outer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        max-width: 60rem;
        margin: 0 auto;
    }

    .auth-layout__inner {
        margin: auto 0;
    }

    .auth-layout__header .logo {
        width: 13rem;
        height: 13rem;
    }

    .auth-layout__bg-logo {
        position: absolute;
        top: -22rem;
        width: 107rem;
        height: 107rem;

        ::v-deep path {
            stroke: rgba(188, 210, 228, 0.03);
        }
    }

    .auth-layout__title {
        color: #ffffff;
        font-family: 'Gotham Light', sans-serif;
        font-size: 2.4rem;
        font-weight: 300;
        text-align: center;
    }

    .auth-layout__form {
        width: 100%;
        margin-top: 3rem;
        padding: 1.5rem;
        border-radius: 1.2rem;
        background: rgba(21, 26, 39, 0.9);
        backdrop-filter: blur(0.45rem);
    }

    .auth-layout__error {
        margin-top: 0.5rem;
        color: #fa6666;
        font-family: 'Gotham Light', sans-serif;
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1.2;
        text-align: center;
    }

    .auth-layout__links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;

        ::v-deep a:not(:first-child) {
            margin-left: 2rem;
        }
    }

    .auth-layout__locale-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;

        p {
            margin-right: 1rem;
            color: #59749c;
            font-family: 'Gotham Book', sans-serif;
            font-weight: 325;
        }
    }

    .auth-layout__locale-select {
        width: 16rem;

        ::v-deep .select__value {
            padding: 1.2rem;
        }
    }

    ::v-deep {
        .google-recaptcha {
            margin-top: 1.5rem;
        }

        .scroll-container {
            position: relative;
            overflow: hidden;
        }

        .scroll-container__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1.5rem;
        }
    }

    @media screen and (min-width: 22.5em) {
        .auth-layout__error {
            font-size: 1rem;
        }
    }

    @media screen and (min-width: 120em) {
        .auth-layout__title {
            font-size: 3.4rem;
        }

        .auth-layout__form {
            margin-top: 4rem;
            padding: 4rem;
        }

        .auth-layout__links {
            margin-top: 2rem;
        }

        .auth-layout__error {
            font-size: 1.2rem;
        }

        ::v-deep {
            .google-recaptcha {
                margin-top: 2rem;
            }
        }
    }
</style>
